import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  {
    CallID: 1,
    CallerNumber: "+1 123-456-7890",
    ReceiverName: "Alice Smith",
    ReceiverNumber: "+1 987-654-3210",
    Date: "2023-12-17",
    Time: "08:30:00",
    DayOfWeek: "Sunday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:05:30",
    Cost: "0.25",
    AnsweredBy: "Alice Smith",
  },
  {
    CallID: 2,
    CallerNumber: "+1 555-123-9876",
    ReceiverName: "Bob Brown",
    ReceiverNumber: "+1 777-987-6543",
    Date: "2023-12-17",
    Time: "10:45:00",
    DayOfWeek: "Sunday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:07:20",
    Cost: "0.35",
    AnsweredBy: "Bob Brown",
  },
  {
    CallID: 3,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Sophie Anderson",
    ReceiverNumber: "+1 444-555-6666",
    Date: "2023-12-18",
    Time: "12:15:00",
    DayOfWeek: "Monday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:06:45",
    Cost: "0.30",
    AnsweredBy: "Sophie Anderson",
  },
  {
    CallID: 4,
    CallerNumber: "+1 777-888-9999",
    ReceiverName: "Michael Davis",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-18",
    Time: "14:30:00",
    DayOfWeek: "Monday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:09:15",
    Cost: "0.45",
    AnsweredBy: "Michael Davis",
  },
  {
    CallID: 5,
    CallerNumber: "+1 333-444-5555",
    ReceiverName: "Olivia Wilson",
    ReceiverNumber: "+1 666-777-8888",
    Date: "2023-12-19",
    Time: "16:45:00",
    DayOfWeek: "Tuesday",
    IsIncoming: false,
    IsOutgoing: false,
    IsMissed: true,
    CallDuration: "00:00:00",
    Cost: "0.00",
    AnsweredBy: "",
  },
  {
    CallID: 6,
    CallerNumber: "+1 888-999-0000",
    ReceiverName: "Daniel Moore",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-19",
    Time: "18:00:00",
    DayOfWeek: "Tuesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:11:00",
    Cost: "0.55",
    AnsweredBy: "Daniel Moore",
  },
  {
    CallID: 7,
    CallerNumber: "+1 555-666-7777",
    ReceiverName: "Emma Clark",
    ReceiverNumber: "+1 999-888-7777",
    Date: "2023-12-20",
    Time: "09:30:00",
    DayOfWeek: "Wednesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:08:30",
    Cost: "0.40",
    AnsweredBy: "Emma Clark",
  },
  {
    CallID: 8,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Sophie Anderson",
    ReceiverNumber: "+1 444-555-6666",
    Date: "2023-12-20",
    Time: "12:45:00",
    DayOfWeek: "Wednesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:05:15",
    Cost: "0.20",
    AnsweredBy: "Sophie Anderson",
  },
  {
    CallID: 9,
    CallerNumber: "+1 777-888-9999",
    ReceiverName: "Michael Davis",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-21",
    Time: "14:00:00",
    DayOfWeek: "Thursday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:07:00",
    Cost: "0.35",
    AnsweredBy: "Michael Davis",
  },
  {
    CallID: 10,
    CallerNumber: "+1 333-444-5555",
    ReceiverName: "Olivia Wilson",
    ReceiverNumber: "+1 666-777-8888",
    Date: "2023-12-21",
    Time: "16:30:00",
    DayOfWeek: "Thursday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:10:45",
    Cost: "0.50",
    AnsweredBy: "Olivia Wilson",
  },
  {
    CallID: 11,
    CallerNumber: "+1 888-999-0000",
    ReceiverName: "Daniel Moore",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-22",
    Time: "10:15:00",
    DayOfWeek: "Friday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:05:30",
    Cost: "0.25",
    AnsweredBy: "Daniel Moore",
  },
  {
    CallID: 12,
    CallerNumber: "+1 555-666-7777",
    ReceiverName: "Emma Clark",
    ReceiverNumber: "+1 999-888-7777",
    Date: "2023-12-22",
    Time: "12:30:00",
    DayOfWeek: "Friday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:08:00",
    Cost: "0.40",
    AnsweredBy: "Emma Clark",
  },
  {
    CallID: 13,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Sophie Anderson",
    ReceiverNumber: "+1 444-555-6666",
    Date: "2023-12-23",
    Time: "14:45:00",
    DayOfWeek: "Saturday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:09:15",
    Cost: "0.45",
    AnsweredBy: "Sophie Anderson",
  },
  {
    CallID: 14,
    CallerNumber: "+1 777-888-9999",
    ReceiverName: "Michael Davis",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-23",
    Time: "18:30:00",
    DayOfWeek: "Saturday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:07:45",
    Cost: "0.35",
    AnsweredBy: "Michael Davis",
  },
  {
    CallID: 15,
    CallerNumber: "+1 333-444-5555",
    ReceiverName: "Olivia Wilson",
    ReceiverNumber: "+1 666-777-8888",
    Date: "2023-12-24",
    Time: "09:00:00",
    DayOfWeek: "Sunday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:12:00",
    Cost: "0.60",
    AnsweredBy: "Olivia Wilson",
  },
  {
    CallID: 16,
    CallerNumber: "+1 888-999-0000",
    ReceiverName: "Daniel Moore",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-24",
    Time: "14:45:00",
    DayOfWeek: "Sunday",
    IsIncoming: false,
    IsOutgoing: false,
    IsMissed: true,
    CallDuration: "00:00:00",
    Cost: "0.00",
    AnsweredBy: "",
  },
  {
    CallID: 17,
    CallerNumber: "+1 555-666-7777",
    ReceiverName: "Emma Clark",
    ReceiverNumber: "+1 999-888-7777",
    Date: "2023-12-25",
    Time: "11:30:00",
    DayOfWeek: "Monday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:10:30",
    Cost: "0.50",
    AnsweredBy: "Emma Clark",
  },
  {
    CallID: 18,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Sophie Anderson",
    ReceiverNumber: "+1 444-555-6666",
    Date: "2023-12-25",
    Time: "15:15:00",
    DayOfWeek: "Monday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:05:45",
    Cost: "0.25",
    AnsweredBy: "Sophie Anderson",
  },
  {
    CallID: 19,
    CallerNumber: "+1 777-888-9999",
    ReceiverName: "Michael Davis",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-26",
    Time: "17:00:00",
    DayOfWeek: "Tuesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:08:00",
    Cost: "0.40",
    AnsweredBy: "Michael Davis",
  },
  {
    CallID: 20,
    CallerNumber: "+1 333-444-5555",
    ReceiverName: "Olivia Wilson",
    ReceiverNumber: "+1 666-777-8888",
    Date: "2023-12-26",
    Time: "19:30:00",
    DayOfWeek: "Tuesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:09:30",
    Cost: "0.45",
    AnsweredBy: "Olivia Wilson",
  },
  {
    CallID: 21,
    CallerNumber: "+1 123-456-7890",
    ReceiverName: "Alice Smith",
    ReceiverNumber: "+1 987-654-3210",
    Date: "2023-12-17",
    Time: "08:30:00",
    DayOfWeek: "Sunday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:05:30",
    Cost: "0.25",
    AnsweredBy: "Alice Smith",
  },
  {
    CallID: 22,
    CallerNumber: "+1 555-123-9876",
    ReceiverName: "Bob Brown",
    ReceiverNumber: "+1 777-987-6543",
    Date: "2023-12-17",
    Time: "10:45:00",
    DayOfWeek: "Sunday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:07:20",
    Cost: "0.35",
    AnsweredBy: "Bob Brown",
  },
  {
    CallID: 23,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Charlie Davis",
    ReceiverNumber: "+1 333-444-5555",
    Date: "2023-12-18",
    Time: "12:15:00",
    DayOfWeek: "Monday",
    IsIncoming: false,
    IsOutgoing: false,
    IsMissed: true,
    CallDuration: "00:00:00",
    Cost: "0.00",
    AnsweredBy: "",
  },
  {
    CallID: 24,
    CallerNumber: "+1 444-555-6666",
    ReceiverName: "David Evans",
    ReceiverNumber: "+1 666-777-8888",
    Date: "2023-12-18",
    Time: "15:30:00",
    DayOfWeek: "Monday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:09:45",
    Cost: "0.45",
    AnsweredBy: "David Evans",
  },
  {
    CallID: 25,
    CallerNumber: "+1 777-888-9999",
    ReceiverName: "Eva Foster",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-19",
    Time: "09:00:00",
    DayOfWeek: "Tuesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:10:30",
    Cost: "0.50",
    AnsweredBy: "Eva Foster",
  },
  {
    CallID: 26,
    CallerNumber: "+1 888-999-0000",
    ReceiverName: "Frank Garcia",
    ReceiverNumber: "+1 222-333-4444",
    Date: "2023-12-19",
    Time: "13:45:00",
    DayOfWeek: "Tuesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:08:00",
    Cost: "0.40",
    AnsweredBy: "Frank Garcia",
  },
  {
    CallID: 27,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Grace Harris",
    ReceiverNumber: "+1 444-555-6666",
    Date: "2023-12-20",
    Time: "16:30:00",
    DayOfWeek: "Wednesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:12:15",
    Cost: "0.60",
    AnsweredBy: "Grace Harris",
  },
  {
    CallID: 28,
    CallerNumber: "+1 222-333-4444",
    ReceiverName: "Henry Ingram",
    ReceiverNumber: "+1 666-777-8888",
    Date: "2023-12-20",
    Time: "19:45:00",
    DayOfWeek: "Wednesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:06:30",
    Cost: "0.30",
    AnsweredBy: "Henry Ingram",
  },
  {
    CallID: 29,
    CallerNumber: "+1 333-444-5555",
    ReceiverName: "Ivy Johnson",
    ReceiverNumber: "+1 888-999-0000",
    Date: "2023-12-21",
    Time: "11:15:00",
    DayOfWeek: "Thursday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:07:45",
    Cost: "0.35",
    AnsweredBy: "Ivy Johnson",
  },
  {
    CallID: 30,
    CallerNumber: "+1 444-555-6666",
    ReceiverName: "Jack Turner",
    ReceiverNumber: "+1 777-888-9999",
    Date: "2023-12-21",
    Time: "14:30:00",
    DayOfWeek: "Thursday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:08:45",
    Cost: "0.40",
    AnsweredBy: "Jack Turner",
  },
  {
    CallID: 31,
    CallerNumber: "+1 555-666-7777",
    ReceiverName: "Katherine White",
    ReceiverNumber: "+1 999-888-7777",
    Date: "2023-12-22",
    Time: "12:00:00",
    DayOfWeek: "Friday",
    IsIncoming: false,
    IsOutgoing: false,
    IsMissed: true,
    CallDuration: "00:00:00",
    Cost: "0.00",
    AnsweredBy: "",
  },
  {
    CallID: 32,
    CallerNumber: "+1 666-777-8888",
    ReceiverName: "Liam Harris",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-22",
    Time: "15:45:00",
    DayOfWeek: "Friday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:10:00",
    Cost: "0.50",
    AnsweredBy: "Liam Harris",
  },
  {
    CallID: 33,
    CallerNumber: "+1 777-888-9999",
    ReceiverName: "Mia Garcia",
    ReceiverNumber: "+1 222-333-4444",
    Date: "2023-12-23",
    Time: "18:30:00",
    DayOfWeek: "Saturday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:09:15",
    Cost: "0.45",
    AnsweredBy: "Mia Garcia",
  },
  {
    CallID: 34,
    CallerNumber: "+1 333-444-5555",
    ReceiverName: "Noah Baker",
    ReceiverNumber: "+1 666-777-8888",
    Date: "2023-12-23",
    Time: "22:00:00",
    DayOfWeek: "Saturday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:11:30",
    Cost: "0.55",
    AnsweredBy: "Noah Baker",
  },
  {
    CallID: 35,
    CallerNumber: "+1 555-123-4567",
    ReceiverName: "Olivia Clark",
    ReceiverNumber: "+1 777-987-6543",
    Date: "2023-12-24",
    Time: "14:30:00",
    DayOfWeek: "Sunday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:08:30",
    Cost: "0.40",
    AnsweredBy: "Olivia Clark",
  },
  {
    CallID: 36,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Peter Turner",
    ReceiverNumber: "+1 555-666-7777",
    Date: "2023-12-24",
    Time: "17:15:00",
    DayOfWeek: "Sunday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:05:45",
    Cost: "0.25",
    AnsweredBy: "Peter Turner",
  },
  {
    CallID: 37,
    CallerNumber: "+1 444-555-6666",
    ReceiverName: "Quinn Foster",
    ReceiverNumber: "+1 888-999-0000",
    Date: "2023-12-25",
    Time: "12:45:00",
    DayOfWeek: "Monday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:07:00",
    Cost: "0.35",
    AnsweredBy: "Quinn Foster",
  },
  {
    CallID: 38,
    CallerNumber: "+1 555-666-7777",
    ReceiverName: "Ryan Miller",
    ReceiverNumber: "+1 999-888-7777",
    Date: "2023-12-25",
    Time: "15:30:00",
    DayOfWeek: "Monday",
    IsIncoming: false,
    IsOutgoing: false,
    IsMissed: true,
    CallDuration: "00:00:00",
    Cost: "0.00",
    AnsweredBy: "",
  },
  {
    CallID: 39,
    CallerNumber: "+1 666-777-8888",
    ReceiverName: "Sophia Adams",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-26",
    Time: "10:00:00",
    DayOfWeek: "Tuesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:06:15",
    Cost: "0.30",
    AnsweredBy: "Sophia Adams",
  },
  {
    CallID: 40,
    CallerNumber: "+1 888-999-0000",
    ReceiverName: "Tyler White",
    ReceiverNumber: "+1 222-333-4444",
    Date: "2023-12-26",
    Time: "14:45:00",
    DayOfWeek: "Tuesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:09:30",
    Cost: "0.45",
    AnsweredBy: "Tyler White",
  },
  {
    CallID: 41,
    CallerNumber: "+1 555-123-9876",
    ReceiverName: "Uma Johnson",
    ReceiverNumber: "+1 777-987-6543",
    Date: "2023-12-27",
    Time: "16:30:00",
    DayOfWeek: "Wednesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:11:45",
    Cost: "0.55",
    AnsweredBy: "Uma Johnson",
  },
  {
    CallID: 42,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Victor Baker",
    ReceiverNumber: "+1 333-444-5555",
    Date: "2023-12-27",
    Time: "19:00:00",
    DayOfWeek: "Wednesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:07:30",
    Cost: "0.35",
    AnsweredBy: "Victor Baker",
  },
  {
    CallID: 43,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Charlie Davis",
    ReceiverNumber: "+1 333-444-5555",
    Date: "2023-12-18",
    Time: "12:15:00",
    DayOfWeek: "Monday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:06:45",
    Cost: "0.30",
    AnsweredBy: "Charlie Davis",
  },
  {
    CallID: 44,
    CallerNumber: "+1 777-888-9999",
    ReceiverName: "David Moore",
    ReceiverNumber: "+1 555-666-7777",
    Date: "2023-12-18",
    Time: "15:30:00",
    DayOfWeek: "Monday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:08:00",
    Cost: "0.40",
    AnsweredBy: "David Moore",
  },
  {
    CallID: 45,
    CallerNumber: "+1 333-444-5555",
    ReceiverName: "Emma Clark",
    ReceiverNumber: "+1 999-888-7777",
    Date: "2023-12-19",
    Time: "09:00:00",
    DayOfWeek: "Tuesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:05:15",
    Cost: "0.25",
    AnsweredBy: "Emma Clark",
  },
  {
    CallID: 46,
    CallerNumber: "+1 444-555-6666",
    ReceiverName: "Frank White",
    ReceiverNumber: "+1 666-777-8888",
    Date: "2023-12-19",
    Time: "11:45:00",
    DayOfWeek: "Tuesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:09:30",
    Cost: "0.45",
    AnsweredBy: "Frank White",
  },
  {
    CallID: 47,
    CallerNumber: "+1 999-888-7777",
    ReceiverName: "Grace Lee",
    ReceiverNumber: "+1 222-333-4444",
    Date: "2023-12-20",
    Time: "14:00:00",
    DayOfWeek: "Wednesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:07:00",
    Cost: "0.35",
    AnsweredBy: "Grace Lee",
  },
  {
    CallID: 48,
    CallerNumber: "+1 222-333-4444",
    ReceiverName: "Hannah Taylor",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-20",
    Time: "16:30:00",
    DayOfWeek: "Wednesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: true,
    CallDuration: "00:00:00",
    Cost: "0.00",
    AnsweredBy: "",
  },
  {
    CallID: 49,
    CallerNumber: "+1 666-777-8888",
    ReceiverName: "Ian Johnson",
    ReceiverNumber: "+1 888-999-0000",
    Date: "2023-12-21",
    Time: "11:00:00",
    DayOfWeek: "Thursday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:08:45",
    Cost: "0.43",
    AnsweredBy: "Ian Johnson",
  },
  {
    CallID: 50,
    CallerNumber: "+1 888-999-0000",
    ReceiverName: "Jack Wilson",
    ReceiverNumber: "+1 555-666-7777",
    Date: "2023-12-21",
    Time: "13:45:00",
    DayOfWeek: "Thursday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:06:15",
    Cost: "0.30",
    AnsweredBy: "Jack Wilson",
  },
  {
    CallID: 51,
    CallerNumber: "+1 555-666-7777",
    ReceiverName: "Karen Brown",
    ReceiverNumber: "+1 444-555-6666",
    Date: "2023-12-22",
    Time: "19:15:00",
    DayOfWeek: "Friday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:10:00",
    Cost: "0.50",
    AnsweredBy: "Karen Brown",
  },
  {
    CallID: 52,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Liam Davis",
    ReceiverNumber: "+1 777-888-9999",
    Date: "2023-12-22",
    Time: "21:30:00",
    DayOfWeek: "Friday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:07:30",
    Cost: "0.38",
    AnsweredBy: "Liam Davis",
  },
  {
    CallID: 53,
    CallerNumber: "+1 777-888-9999",
    ReceiverName: "Mia Miller",
    ReceiverNumber: "+1 999-888-7777",
    Date: "2023-12-23",
    Time: "08:00:00",
    DayOfWeek: "Saturday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:05:45",
    Cost: "0.28",
    AnsweredBy: "Mia Miller",
  },
  {
    CallID: 54,
    CallerNumber: "+1 333-444-5555",
    ReceiverName: "Noah Taylor",
    ReceiverNumber: "+1 555-666-7777",
    Date: "2023-12-23",
    Time: "10:45:00",
    DayOfWeek: "Saturday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:09:15",
    Cost: "0.42",
    AnsweredBy: "Noah Taylor",
  },
  {
    CallID: 55,
    CallerNumber: "+1 555-666-7777",
    ReceiverName: "Olivia Wilson",
    ReceiverNumber: "+1 666-777-8888",
    Date: "2023-12-24",
    Time: "12:00:00",
    DayOfWeek: "Sunday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:07:30",
    Cost: "0.35",
    AnsweredBy: "Olivia Wilson",
  },
  {
    CallID: 56,
    CallerNumber: "+1 888-999-0000",
    ReceiverName: "Peter Moore",
    ReceiverNumber: "+1 444-555-6666",
    Date: "2023-12-24",
    Time: "14:30:00",
    DayOfWeek: "Sunday",
    IsIncoming: false,
    IsOutgoing: false,
    IsMissed: true,
    CallDuration: "00:00:00",
    Cost: "0.00",
    AnsweredBy: "",
  },
  {
    CallID: 57,
    CallerNumber: "+1 666-777-8888",
    ReceiverName: "Qin Liu",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-25",
    Time: "09:30:00",
    DayOfWeek: "Monday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:06:15",
    Cost: "0.31",
    AnsweredBy: "Qin Liu",
  },
  {
    CallID: 58,
    CallerNumber: "+1 111-222-3333",
    ReceiverName: "Riley Clark",
    ReceiverNumber: "+1 777-888-9999",
    Date: "2023-12-25",
    Time: "11:45:00",
    DayOfWeek: "Monday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:08:30",
    Cost: "0.39",
    AnsweredBy: "Riley Clark",
  },
  {
    CallID: 59,
    CallerNumber: "+1 777-888-9999",
    ReceiverName: "Sophia Brown",
    ReceiverNumber: "+1 999-888-7777",
    Date: "2023-12-26",
    Time: "14:00:00",
    DayOfWeek: "Tuesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:07:00",
    Cost: "0.35",
    AnsweredBy: "Sophia Brown",
  },
  {
    CallID: 60,
    CallerNumber: "+1 222-333-4444",
    ReceiverName: "Thomas Wilson",
    ReceiverNumber: "+1 555-666-7777",
    Date: "2023-12-26",
    Time: "16:30:00",
    DayOfWeek: "Tuesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:08:00",
    Cost: "0.40",
    AnsweredBy: "Thomas Wilson",
  },
  {
    CallID: 61,
    CallerNumber: "+1 555-666-7777",
    ReceiverName: "Uma Patel",
    ReceiverNumber: "+1 444-555-6666",
    Date: "2023-12-27",
    Time: "11:00:00",
    DayOfWeek: "Wednesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:08:45",
    Cost: "0.43",
    AnsweredBy: "Uma Patel",
  },
  {
    CallID: 62,
    CallerNumber: "+1 444-555-6666",
    ReceiverName: "Victor Anderson",
    ReceiverNumber: "+1 666-777-8888",
    Date: "2023-12-27",
    Time: "13:45:00",
    DayOfWeek: "Wednesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:06:15",
    Cost: "0.30",
    AnsweredBy: "Victor Anderson",
  },
  {
    CallID: 63,
    CallerNumber: "+1 888-999-0000",
    ReceiverName: "Daniel Moore",
    ReceiverNumber: "+1 111-222-3333",
    Date: "2023-12-27",
    Time: "10:45:00",
    DayOfWeek: "Wednesday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:06:15",
    Cost: "0.30",
    AnsweredBy: "Daniel Moore",
  },
  {
    CallID: 64,
    CallerNumber: "+1 555-666-7777",
    ReceiverName: "Emma Clark",
    ReceiverNumber: "+1 999-888-7777",
    Date: "2023-12-27",
    Time: "12:00:00",
    DayOfWeek: "Wednesday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:05:45",
    Cost: "0.25",
    AnsweredBy: "Emma Clark",
  },
  {
    CallID: 65,
    CallerNumber: "+1 123-456-7890",
    ReceiverName: "Alice Smith",
    ReceiverNumber: "+1 987-654-3210",
    Date: "2023-12-17",
    Time: "08:30:00",
    DayOfWeek: "Sunday",
    IsIncoming: true,
    IsOutgoing: false,
    IsMissed: false,
    CallDuration: "00:05:30",
    Cost: "0.25",
    AnsweredBy: "Alice Smith",
  },
  {
    CallID: 66,
    CallerNumber: "+1 555-123-9876",
    ReceiverName: "Bob Brown",
    ReceiverNumber: "+1 777-987-6543",
    Date: "2023-12-17",
    Time: "10:45:00",
    DayOfWeek: "Sunday",
    IsIncoming: false,
    IsOutgoing: true,
    IsMissed: false,
    CallDuration: "00:07:20",
    Cost: "0.35",
    AnsweredBy: "Bob Brown",
  },
  {
    CallID: 67,
    "CallerNumber": "+1 999-888-7777",
    "ReceiverName": "Uma Patel",
    "ReceiverNumber": "+1 555-666-7777",
    "Date": "2023-12-27",
    "Time": "09:00:00",
    "DayOfWeek": "Wednesday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:06:00",
    "Cost": "0.30",
    "AnsweredBy": "Uma Patel"
  },
  {
    CallID: 68,
    "CallerNumber": "+1 111-222-3333",
    "ReceiverName": "Victor Hill",
    "ReceiverNumber": "+1 333-444-5555",
    "Date": "2023-12-27",
    "Time": "11:15:00",
    "DayOfWeek": "Wednesday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:09:30",
    "Cost": "0.45",
    "AnsweredBy": "Victor Hill"
  },
  {
    CallID: 69,
    "CallerNumber": "+1 555-666-7777",
    "ReceiverName": "David Miller",
    "ReceiverNumber": "+1 999-888-5555",
    "Date": "2023-12-22",
    "Time": "09:30:00",
    "DayOfWeek": "Friday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:10:30",
    "Cost": "0.50",
    "AnsweredBy": "David Miller"
  },
  {
    CallID: 70,
    "CallerNumber": "+1 666-777-8888",
    "ReceiverName": "Sophie Anderson",
    "ReceiverNumber": "+1 444-555-6666",
    "Date": "2023-12-22",
    "Time": "14:00:00",
    "DayOfWeek": "Friday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:09:00",
    "Cost": "0.45",
    "AnsweredBy": "Sophie Anderson"
  },
  {
    CallID: 71,
    "CallerNumber": "+1 123-456-7890",
    "ReceiverName": "Alice Smith",
    "ReceiverNumber": "+1 987-654-3210",
    "Date": "2023-12-17",
    "Time": "08:30:00",
    "DayOfWeek": "Sunday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:05:30",
    "Cost": "0.25",
    "AnsweredBy": "Alice Smith"
  },
  {
    CallID: 72,
    "CallerNumber": "+1 555-123-9876",
    "ReceiverName": "Bob Brown",
    "ReceiverNumber": "+1 777-987-6543",
    "Date": "2023-12-17",
    "Time": "10:45:00",
    "DayOfWeek": "Sunday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:07:20",
    "Cost": "0.35",
    "AnsweredBy": "Bob Brown"
  },
  {
    CallID: 73,
    "CallerNumber": "+1 111-222-3333",
    "ReceiverName": "Charlie Davis",
    "ReceiverNumber": "+1 333-444-5555",
    "Date": "2023-12-18",
    "Time": "12:15:00",
    "DayOfWeek": "Monday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:03:45",
    "Cost": "0.20",
    "AnsweredBy": "Charlie Davis"
  },
  {
    CallID: 74,
    "CallerNumber": "+1 777-888-9999",
    "ReceiverName": "David Evans",
    "ReceiverNumber": "+1 444-555-6666",
    "Date": "2023-12-18",
    "Time": "14:30:00",
    "DayOfWeek": "Monday",
    "IsIncoming": false,
    "IsOutgoing": false,
    "IsMissed": true,
    "CallDuration": "00:00:00",
    "Cost": "0.00",
    "AnsweredBy": ""
  },
  {
    CallID: 75,
    "CallerNumber": "+1 333-444-5555",
    "ReceiverName": "Emma Foster",
    "ReceiverNumber": "+1 111-222-3333",
    "Date": "2023-12-19",
    "Time": "16:45:00",
    "DayOfWeek": "Tuesday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:06:30",
    "Cost": "0.30",
    "AnsweredBy": "Emma Foster"
  },
  {
    CallID: 76,
    "CallerNumber": "+1 555-666-7777",
    "ReceiverName": "Frank Green",
    "ReceiverNumber": "+1 999-888-7777",
    "Date": "2023-12-19",
    "Time": "18:00:00",
    "DayOfWeek": "Tuesday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:10:15",
    "Cost": "0.50",
    "AnsweredBy": "Frank Green"
  },
  {
    CallID: 77,
    "CallerNumber": "+1 888-999-0000",
    "ReceiverName": "Grace Harris",
    "ReceiverNumber": "+1 777-888-9999",
    "Date": "2023-12-20",
    "Time": "09:15:00",
    "DayOfWeek": "Wednesday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:08:45",
    "Cost": "0.40",
    "AnsweredBy": "Grace Harris"
  },
  {
    CallID: 78,
    "CallerNumber": "+1 444-555-6666",
    "ReceiverName": "Henry Irwin",
    "ReceiverNumber": "+1 111-222-3333",
    "Date": "2023-12-20",
    "Time": "11:30:00",
    "DayOfWeek": "Wednesday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:09:00",
    "Cost": "0.45",
    "AnsweredBy": "Henry Irwin"
  },
  {
    CallID: 79,
    "CallerNumber": "+1 222-333-4444",
    "ReceiverName": "Ivy Johnson",
    "ReceiverNumber": "+1 555-123-9876",
    "Date": "2023-12-21",
    "Time": "13:45:00",
    "DayOfWeek": "Thursday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:04:30",
    "Cost": "0.25",
    "AnsweredBy": "Ivy Johnson"
  },
  {
    CallID: 80,
    "CallerNumber": "+1 666-777-8888",
    "ReceiverName": "Jack King",
    "ReceiverNumber": "+1 888-999-0000",
    "Date": "2023-12-21",
    "Time": "15:15:00",
    "DayOfWeek": "Thursday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:07:15",
    "Cost": "0.35",
    "AnsweredBy": "Jack King"
  },
  {
    CallID: 81,
    "CallerNumber": "+1 999-888-7777",
    "ReceiverName": "Kelly Lee",
    "ReceiverNumber": "+1 555-666-7777",
    "Date": "2023-12-22",
    "Time": "17:00:00",
    "DayOfWeek": "Friday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:03:00",
    "Cost": "0.15",
    "AnsweredBy": "Kelly Lee"
  },
  {
    CallID: 82,
    "CallerNumber": "+1 111-222-3333",
    "ReceiverName": "Leo Miller",
    "ReceiverNumber": "+1 333-444-5555",
    "Date": "2023-12-22",
    "Time": "19:30:00",
    "DayOfWeek": "Friday",
    "IsIncoming": false,
    "IsOutgoing": false,
    "IsMissed": true,
    "CallDuration": "00:00:00",
    "Cost": "0.00",
    "AnsweredBy": ""
  },
  {
    CallID: 83,
    "CallerNumber": "+1 777-888-9999",
    "ReceiverName": "Mia Nelson",
    "ReceiverNumber": "+1 444-555-6666",
    "Date": "2023-12-23",
    "Time": "11:45:00",
    "DayOfWeek": "Saturday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:05:45",
    "Cost": "0.30",
    "AnsweredBy": "Mia Nelson"
  },
  {
    CallID: 84,
    "CallerNumber": "+1 333-444-5555",
    "ReceiverName": "Nathan O'Connor",
    "ReceiverNumber": "+1 111-222-3333",
    "Date": "2023-12-23",
    "Time": "14:00:00",
    "DayOfWeek": "Saturday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:06:30",
    "Cost": "0.30",
    "AnsweredBy": "Nathan O'Connor"
  },
  {
    CallID: 85,
    "CallerNumber": "+1 444-555-6666",
    "ReceiverName": "Olivia Parker",
    "ReceiverNumber": "+1 666-777-8888",
    "Date": "2023-12-24",
    "Time": "16:15:00",
    "DayOfWeek": "Sunday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:08:00",
    "Cost": "0.40",
    "AnsweredBy": "Olivia Parker"
  },
  {
    CallID: 86,
    "CallerNumber": "+1 555-666-7777",
    "ReceiverName": "Peter Quinn",
    "ReceiverNumber": "+1 999-888-7777",
    "Date": "2023-12-24",
    "Time": "18:45:00",
    "DayOfWeek": "Sunday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:09:30",
    "Cost": "0.45",
    "AnsweredBy": "Peter Quinn"
  },
  {
    CallID: 87,
    "CallerNumber": "+1 888-999-0000",
    "ReceiverName": "Quincy Reid",
    "ReceiverNumber": "+1 777-888-9999",
    "Date": "2023-12-25",
    "Time": "10:30:00",
    "DayOfWeek": "Monday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:07:45",
    "Cost": "0.35",
    "AnsweredBy": "Quincy Reid"
  },
  {
    CallID: 88,
    "CallerNumber": "+1 444-555-6666",
    "ReceiverName": "Rachel Smith",
    "ReceiverNumber": "+1 111-222-3333",
    "Date": "2023-12-25",
    "Time": "13:45:00",
    "DayOfWeek": "Monday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:08:15",
    "Cost": "0.40",
    "AnsweredBy": "Rachel Smith"
  },
  {
    CallID: 89,
    "CallerNumber": "+1 222-333-4444",
    "ReceiverName": "Sam Turner",
    "ReceiverNumber": "+1 555-123-9876",
    "Date": "2023-12-26",
    "Time": "15:00:00",
    "DayOfWeek": "Tuesday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:05:30",
    "Cost": "0.25",
    "AnsweredBy": "Sam Turner"
  },
  {
    CallID: 90,
    "CallerNumber": "+1 666-777-8888",
    "ReceiverName": "Tina Young",
    "ReceiverNumber": "+1 888-999-0000",
    "Date": "2023-12-26",
    "Time": "17:30:00",
    "DayOfWeek": "Tuesday",
    "IsIncoming": false,
    "IsOutgoing": false,
    "IsMissed": true,
    "CallDuration": "00:00:00",
    "Cost": "0.00",
    "AnsweredBy": ""
  },
  {
    CallID: 91,
    "CallerNumber": "+1 123-456-7890",
    "ReceiverName": "Alice Smith",
    "ReceiverNumber": "+1 987-654-3210",
    "Date": "2023-12-17",
    "Time": "08:30:00",
    "DayOfWeek": "Sunday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:05:30",
    "Cost": "0.25",
    "AnsweredBy": "Alice Smith"
  },
  {
    CallID: 92,
    "CallerNumber": "+1 555-123-9876",
    "ReceiverName": "Bob Brown",
    "ReceiverNumber": "+1 777-987-6543",
    "Date": "2023-12-17",
    "Time": "10:45:00",
    "DayOfWeek": "Sunday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:07:20",
    "Cost": "0.35",
    "AnsweredBy": "Bob Brown"
  },
  {
    CallID: 93,
    "CallerNumber": "+1 111-222-3333",
    "ReceiverName": "Sophie Anderson",
    "ReceiverNumber": "+1 444-555-6666",
    "Date": "2023-12-18",
    "Time": "12:15:00",
    "DayOfWeek": "Monday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:06:45",
    "Cost": "0.30",
    "AnsweredBy": "Sophie Anderson"
  },
  {
    CallID: 94,
    "CallerNumber": "+1 777-888-9999",
    "ReceiverName": "Michael Davis",
    "ReceiverNumber": "+1 111-222-3333",
    "Date": "2023-12-18",
    "Time": "15:30:00",
    "DayOfWeek": "Monday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:09:15",
    "Cost": "0.45",
    "AnsweredBy": "Michael Davis"
  },
  {
    CallID: 95,
    "CallerNumber": "+1 444-555-6666",
    "ReceiverName": "Olivia Wilson",
    "ReceiverNumber": "+1 666-777-8888",
    "Date": "2023-12-19",
    "Time": "09:00:00",
    "DayOfWeek": "Tuesday",
    "IsIncoming": false,
    "IsOutgoing": false,
    "IsMissed": true,
    "CallDuration": "00:00:00",
    "Cost": "0.00",
    "AnsweredBy": ""
  },
  {
    CallID: 96,
    "CallerNumber": "+1 888-999-0000",
    "ReceiverName": "Daniel Moore",
    "ReceiverNumber": "+1 111-222-3333",
    "Date": "2023-12-19",
    "Time": "14:45:00",
    "DayOfWeek": "Tuesday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:08:30",
    "Cost": "0.40",
    "AnsweredBy": "Daniel Moore"
  },
  {
    CallID: 97,
    "CallerNumber": "+1 555-666-7777",
    "ReceiverName": "Emma Clark",
    "ReceiverNumber": "+1 999-888-7777",
    "Date": "2023-12-20",
    "Time": "18:30:00",
    "DayOfWeek": "Wednesday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:12:00",
    "Cost": "0.60",
    "AnsweredBy": "Emma Clark"
  },
  {
    CallID: 98,
    "CallerNumber": "+1 222-333-4444",
    "ReceiverName": "Grace Lee",
    "ReceiverNumber": "+1 999-888-7777",
    "Date": "2023-12-20",
    "Time": "22:00:00",
    "DayOfWeek": "Wednesday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:05:45",
    "Cost": "0.25",
    "AnsweredBy": "Grace Lee"
  },
  {
    CallID: 99,
    "CallerNumber": "+1 333-444-5555",
    "ReceiverName": "Mark Taylor",
    "ReceiverNumber": "+1 777-555-9999",
    "Date": "2023-12-21",
    "Time": "11:15:00",
    "DayOfWeek": "Thursday",
    "IsIncoming": false,
    "IsOutgoing": true,
    "IsMissed": false,
    "CallDuration": "00:08:45",
    "Cost": "0.40",
    "AnsweredBy": "Mark Taylor"
  },
  {
    CallID: 100,
    "CallerNumber": "+1 555-123-4567",
    "ReceiverName": "Bob Brown",
    "ReceiverNumber": "+1 777-987-6543",
    "Date": "2023-12-21",
    "Time": "15:45:00",
    "DayOfWeek": "Thursday",
    "IsIncoming": true,
    "IsOutgoing": false,
    "IsMissed": false,
    "CallDuration": "00:07:15",
    "Cost": "0.35",
    "AnsweredBy": "Bob Brown"
  }
];

export const callHistorySlice = createSlice({
  name: "callHistory",
  initialState,
  reducers: {
    addCallRecord: (state, action) => {
      state = [...state, action.payload];
    },
    removeCallRecord: (state, action) => {
      state = state.filter((item) => action.payload.id !== item.id);
    },
  },
});

// Action creators are generated for each case reducer function
export const { addCallRecord, removeCallRecord } = callHistorySlice.actions;

export default callHistorySlice.reducer;
